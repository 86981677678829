import React from "react";
import styled from "styled-components";
import { siteColors } from "../constants/colors";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { EmailLink, ExternalLink, InternalLink } from "../components/sitelinks";

const MainContainer = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   font-size: 1.2em;
`;

const HeaderContainer = styled.div`
  width: 100%
  display: flex;
  color:  ${ siteColors.light };
  font-size 3em;
  justify-contents: center;
  align-items: center;
  margin-top: 1em;
`;


const FaqContainer = styled.div`
  margin-top: 3em;
  width: 720px;
  max-width: 720px;
  background: ${ siteColors.darker };
  color:  ${ siteColors.light };
  border: 0.15rem solid ${ siteColors.main };
  border-radius: 0.15em;
`;


const SectionHeading = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.4em;
`;

const FaqContents = styled.div`
  padding-top: 1.0em;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  background: ${ siteColors.darkest };
`;

const FaqItem = styled.div`
  padding-top: 0.5em;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  background: ${ siteColors.darkest };
`;

const FaqQuestion = styled.div`
  font-weight: bold;
`;

const FaqAnswer = styled.div`
padding-top: 0.75em;
`;

const FaqPage = () => (
        <Layout>
        <SEO title= "FAQ" />
        <MainContainer>
          <HeaderContainer>Frequently Asked Questions</HeaderContainer>
          <FaqContainer>
        <FaqContents>
        <div>
        <ExternalLink href="#anzovinrigtools">Is Contour Rig Tools the same thing as Anzovin Rig Tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#tryrigtools">How can I try Contour Rig Tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#buyrigtools">How can I buy Contour Rig Tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#pricing">How much does Contour Rig Tools cost?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#permanentlicense">Is a permanent license available for Contour Rig Tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#support">How do I get support for Contour Rig Tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#indiegogobenefits">I contributed to the IndieGoGo campaign. How can I claim my benefits?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#mayaversions">Which versions of Maya does Contour Rig Tools support?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#releaserigs">Can I release rigs that I’ve created with Contour Rig Tools publicly?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#floatinglicenses">Does Contour Rig Tools support floating licenses?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#internetconnectivity">Will Contour Rig Tools licensing allow me to use the software if I’m not currently connected to the Internet?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#expiredlicense">If I allow my license to expire, will I be able to continue using my files?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#otherplatforms"></ExternalLink>
        </div>

        <div>
        <ExternalLink href="#otherplatforms">Are there plans to bring Contour Rig Tools to other DCCs, or to game engines?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#rigtoolsandephemeraltools">Does Contour Rig Tools include ephemeral rigging tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#scripting">Can I script Contour Rig Tools?</ExternalLink>
        </div>
        <div>
        <ExternalLink href="#socialmedia">Is Notional Pipe on social media?</ExternalLink>
        </div>
        </FaqContents>
            <FaqItem id="anzovinrigtools">
            <FaqQuestion>
               Is Contour Rig Tools the same thing as Anzovin Rig Tools?
            </FaqQuestion>
        <FaqAnswer>
        Yes. We’ve rebranded it as Contour Rig Tools to avoid confusion with Epic’s ART rigging tools, and because it is no longer being published by Anzovin Studio. Raf Anzovin is a partner in Notional Pipe, and continues to guide Contour Rig Tool’s development.
        </FaqAnswer>
            </FaqItem>

        <FaqItem id="tryrigtools">
        <FaqQuestion>
        How can I try Contour Rig Tools?
        </FaqQuestion>
        <FaqAnswer>
        You can download Rig Tools from our <InternalLink to="/software">software page</InternalLink>. Rig Tools comes with a 30-day trial, and has a free animator mode that allows animating Rig Tools rigs, like the Contour Man example rig you can get <InternalLink to="/learn">here</InternalLink>.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="buyrigtools">
        <FaqQuestion>
        How can I buy Contour Rig Tools?
        </FaqQuestion>
        <FaqAnswer>
        Contour Rig Tools is available on a subscription basis. We're still working on the subscription management features of the website, but Rig Tools has been a long time coming, and we don't want to wait any longer before making it available, so we're accepting orders and manually managing subscriptions for the moment. You can purchase Rig Tools licenses by getting touch with us at <EmailLink href="mailto: sales@notionalpipe.com">sales@notionalpipe.com</EmailLink>.
    </FaqAnswer>
        </FaqItem>

        <FaqItem id="pricing">
        <FaqQuestion>
        How much does Contour Rig Tools cost?
        </FaqQuestion>
        <FaqAnswer>
        See <InternalLink to="/software">here</InternalLink> for Rig Tools pricing.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="permanentlicense">
        <FaqQuestion>
        Is a permanent license available for Contour Rig Tools?
        </FaqQuestion>
        <FaqAnswer>
        Contour Rig Tools is only available with a subscription license. There are many legitimate concerns about subscription-only software, but we believe that it is the right approach for us. Allowing animators to use Contour rigs for free is important to us, and a subscription model makes it viable for us to continue maintaining and improving Contour Rig Tools, while keeping access to the software free for animators.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="support">
        <FaqQuestion>
        How do I get support for Contour Rig Tools?
        </FaqQuestion>
        <FaqAnswer>
        For Contour Rig Tools support, please email us at <EmailLink href="mailto: support@notionalpipe.com">support@notionalpipe.com</EmailLink>.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="indiegogobenefits">
        <FaqQuestion>
        I contributed to the IndieGoGo campaign. How can I claim my benefits?
        </FaqQuestion>
        <FaqAnswer>
        We've tried to reach all of the IndieGoGo contributors entitled to seats of Rig Tools by email, and have made an effort (and continue to) to find those we haven't been able to reach. If you contributed to the IndieGoGo campaign and have not heard from us, please email us at <EmailLink href="mailto: support@notionalpipe.com">support@notionalpipe.com</EmailLink>.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="mayaversions">
        <FaqQuestion>
Which versions of Maya does Contour Rig Tools support?
        </FaqQuestion>
        <FaqAnswer>
           <p>We generally support at least three versions of Maya at a time, on Windows, Linux, and macOS. The latest release of Contour, 1.0.9, supports Maya 2020 through Maya 2024.</p>

           <p>In general, we only support the most recent patch release of each Maya version, and in many cases, Contour will fail to run in early patch releases. We do not support Maya 2023.0, Maya 2023.1, or Maya 2024.0. If you are running Maya 2023, it must be Maya 2023.2 or later. If you are running Maya 2024, it must be Maya 2024.1 or later.</p>

           <div>If you're running Maya 2018 or Maya 2019, you can install Contour 1.0.7, but note that it is unsupported and these Maya versions will not receive further updates.</div>
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="releaserigs">
        <FaqQuestion>
        Can I release rigs that I’ve created with Contour Rig Tools publicly?
        </FaqQuestion>
        <FaqAnswer>
        Yes, Contour Rig Tools rigs are freely re-distributable, and can be animated using the free animator-only mode of Contour Rig Tools.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="floatinglicenses">
        <FaqQuestion>
        Does Contour Rig Tools support floating licenses?
        </FaqQuestion>
        <FaqAnswer>
          We do support floating licenses. If you would like to use floating licenses with Rig Tools please get in touch with us at <EmailLink href="mailto: support@notionalpipe.com">support@notionalpipe.com</EmailLink> and we can assist you in setting up a license server.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="internetconnectivity">
        <FaqQuestion>
Will Contour Rig Tools licensing allow me to use the software if I’m not currently connected to the Internet?
        </FaqQuestion>
        <FaqAnswer>
        <p>Yes. To use Rig Tools on a machine that is never connected to the Internet, you will have to use floating licenses, and be able to connect to a license server that does have access to the Internet, or to a proxy that has access to the Internet.</p>

<div> Node-locked licenses require that your machine be connected to the Internet when you activate your license, and have at least intermittent Internet access thereafter. If you lose Internet connectivity while using a node-locked license, Rig Tools will continue to function for a grace period, currently seven (7) days.</div>
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="expiredlicense">
        <FaqQuestion>
           If I allow my license to expire, will I be able to continue using my files?
        </FaqQuestion>
        <FaqAnswer>
           Yes. Since the animator-only version of Contour is free, you will never be unable to use rigs you have created with Contour. You will need to resubscribe if you wish to alter those rigs, or create new ones.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="otherplatforms">
        <FaqQuestion>
Are there plans to bring Contour Rig Tools to other DCCs, or to game engines?
        </FaqQuestion>
        <FaqAnswer>
We have no concrete plans to port Contour Rig Tools to other systems, but it's something we want to explore once the dust settles on our initial release. If you'd like to discuss the possibility of bringing Contour Rig Tools to other systems with us, please contact us at <EmailLink href="mailto: business@notionalpipe.com">business@notionalpipe.com</EmailLink>
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="rigtoolsandephemeraltools">
        <FaqQuestion>
Does Contour Rig Tools include ephemeral rigging tools?
        </FaqQuestion>
        <FaqAnswer>
<p>Raf Anzovin’s ephemeral rigging system, which can be seen in action on his blog <ExternalLink href="http://www.justtodosomethingbad.com" target="_blank">Just To Do Something Bad</ExternalLink>, allows an animator to control a character with “ephemeral” manipulation modes instead of a persistent rig.</p>

<p>The ephemeral rig system is primarily about control rigging, while Contour Rig Tools is primarily about deformation rigging. They are two different tools, but they are highly complementary, and every ephemeral rig Raf has created so far has also used Contour Rig Tools.</p>

<div>The ephemeral rig system is now a Notional Pipe project, but it’s in a much earlier stage of development. We plan to bring it to market in the future, but do not have a specific date at this time.</div>
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="scripting">
        <FaqQuestion>
           Can I script Contour Rig Tools?
        </FaqQuestion>
        <FaqAnswer>
        Yes, Rig Tools has a Python API. For more information, see <ExternalLink href="https://manuals.notionalpipe.com/rigtools/10-the-predeprecated-api/index.html" target="_blank">the API section of the manual</ExternalLink>.
        </FaqAnswer>
        </FaqItem>

        <FaqItem id="socialmedia">
        <FaqQuestion>
           Is Notional Pipe on social media?
        </FaqQuestion>
        <FaqAnswer>
        Yes, we have a <ExternalLink href="https://www.youtube.com/channel/UCoTin4y_JsdGbK2imeiGTQA" target="_blank">Youtube channel</ExternalLink>, and you can follow <ExternalLink href="https://twitter.com/NotionalPipe" target="_blank">us on Twitter</ExternalLink>.
        </FaqAnswer>
        </FaqItem>

    </FaqContainer>
        </MainContainer>
        </Layout>
);

export default FaqPage;
